/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type InterviewMeListFeedbackStatus = typeof InterviewMeListFeedbackStatus[keyof typeof InterviewMeListFeedbackStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewMeListFeedbackStatus = {
  pending: 'pending',
  started: 'started',
  submitted: 'submitted',
} as const;
