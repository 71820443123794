/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type OrganizationAiRecorderType = typeof OrganizationAiRecorderType[keyof typeof OrganizationAiRecorderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationAiRecorderType = {
  bright_hire: 'bright_hire',
  metaview: 'metaview',
  gong: 'gong',
  none: 'none',
} as const;
